const en = 'test' // test = 测试服， release = 正式服
export const platform = 'msyz' // msyz = 面试有招，digitalEmployment = 数字化就业系统
export let apiUrl = null // 网络请求域名
export let H5Url = null // 面试有招H5访问链接
if (en === 'test') {
  if (platform === 'msyz') { // 面试有招测试服
    apiUrl = 'http://ms.czlks.com/v1/web'
    H5Url = 'http://h5.czlks.com/#/' // 测试服：http://h5.czlks.com
  } else if (platform === 'digitalEmployment') { // 数字化就业系统测试服
    apiUrl = 'http://det.czlks.com'
    H5Url = 'http://deh5t.czlks.com/#/' // 测试服：http://deh5t.czlks.com/#/
  }
} else if (en === 'release') {
  if (platform === 'msyz') { // 面试有招正试服
    apiUrl = 'https://msapi.czlks.com/v1/web'
    H5Url = 'https://mm.czlks.com/#/' // 正式服：https://mm.czlks.com
  } else if (platform === 'digitalEmployment') { // 数字化就业系统正试服
    apiUrl = 'https://deapi.gxtizi.com'
    H5Url = 'https://demh.gxtizi.com/#/' // 正式服：https://demh.gxtizi.com
  }
}
export const upyunUrl = 'https://msimg.czlks.com'
export const upFileYunUrl = 'https://msimg.czlks.com'
export const youPaiYunUrl = 'https://v0.api.upyun.com'
